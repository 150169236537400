import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import layoutStyles from "../styles/css/layout.module.scss"
import mainStyles from '../styles/css/main.module.scss'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description}
        />

        <div className={layoutStyles.introSection}>
          <div className={layoutStyles.introText}>
            <h1>{post.frontmatter.title}</h1>
            <p><i>{post.frontmatter.description}</i></p>
          </div>
        </div>

        <section className={layoutStyles.mainSection}>

          <div className={layoutStyles.contentDiv}>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>

        </section>

        <section className={layoutStyles.navSection}>
          <div className={layoutStyles.navColumnLeft}>
            {previous && (
              <Link className={`${mainStyles.btn_large} ${mainStyles.btn_secondary}`} to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </div>
          
          <div className={layoutStyles.navColumnRight}>
            {next && (
              <Link className={`${mainStyles.btn_large} ${mainStyles.btn_secondary}`} to={next.fields.slug} rel="next">
               {next.frontmatter.title} → 
              </Link>
            )}
          </div>
        </section>
      
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query GuidePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`
